@import "../../styles/variables";

// $blue:    #2196F3;
// $red:     #EF5350;
// $yellow:  #ffc107;
// $green:   #66BB6A;
// $cyan:    #00BCD4;

// $primary:       $blue;
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;


$offset: 187;
$duration: 1.4s;

// Container
.loader-container {
  margin-top: 3rem;
  text-align: center;
}

// Loader
.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash $duration ease-in-out infinite, 
    colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
  0% { stroke: $primary; }
  25% { stroke: $info; }
  50% { stroke: $success; }
  75% { stroke: $warning; }
  100% { stroke: $danger; }
}

@keyframes dash {
 0% { stroke-dashoffset: $offset; }
 50% {
   stroke-dashoffset: $offset/4;
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }
}