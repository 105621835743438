
.theme-dark,
.theme-gray {
  .app-header {
    .bg-color-light,
    .bg-color-warning {
      .header-btn {
        color: #fff !important;
      }
    }
  }
}
