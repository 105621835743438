.ribbon-container {
  position: relative;
  display: block; // when used on inline elements like `a`

  .ribbon-wrapper {
    position: absolute;
    overflow: hidden;
    width: 85px;
    height: 88px;
    top: -3px;
    right: -3px;
  }

  .ribbon {
    position: relative;
    display: block;
    text-align: center;
    font-size: 15px;
    color: $white;
    transform: rotate(45deg);
    padding: 7px 0;
    left: -5px;
    top: 15px;
    width: 120px;
    line-height: 20px;
    background-color: $gray-700;
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
    &:before,
    &:after {
      position: absolute;
      content: '';
      line-height: 0;
      border-top: 2px solid $gray-700;
      border-left: 2px solid transparent;
      border-right: 2px solid transparent;
      bottom: -2px;
    }
    &:before {
      left: 0;
      bottom: -1px;
    }
    &:after {
      right: 0;
    }
  }

  // color
  &.ribbon-primary { @include ribbon-variant ($primary); }
  &.ribbon-success { @include ribbon-variant ($success); }
  &.ribbon-info { @include ribbon-variant ($info); }
  &.ribbon-warning { @include ribbon-variant ($warning); }
  &.ribbon-danger { @include ribbon-variant ($danger); }
}