// Use: Product cards

.card-white {
  color: $body-color;
  background-color: #fff;
}

// 
.item-card {
  position: relative;
  transition: 0.2s linear;
  border-radius: $border-radius;
  &:hover {
    box-shadow: 0 0 15px rgba(0,0,0, .1);
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .card__image {
    display: block;
    text-decoration: none;
    padding: 30px 15px;
    height: 300px;
    border-radius: $border-radius $border-radius 0 0;
    background-color: rgba(#000, .035);
    text-align: center;
  }

  .card__title {
    font-size: 1rem;
    a {
      color: $body-color;
      font-weight: $font-weight-500;
      text-decoration: none;
    }
    span {
      display: block;
      font-size: $font-size-sm;
      opacity: .5;
    }
  }

  .card__body {
    position: relative;
    padding: 30px 15px;
    border-radius: 0 0 $border-radius $border-radius;
  }

  .card__price {
    position: absolute;
    top: 30px;
    right: 15px;
    span {
      display: block;
      color: $gray-600;
      &.type--strikethrough {
        opacity: .5;
        text-decoration: line-through;
      }
    }
  }

  // 
  &.card__horizontal {
    position: relative;

    .card__image {
      width: 50%;
      border-radius: $border-radius 0 0 $border-radius;
    }

    .card__body {
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      padding: 40px 30px;
      border-radius: 0 $border-radius $border-radius 0;
    }
    .card__price {
      top: 30px;
      right: 30px;
    }

    .card__desc {
      margin: 0 25% 3em 0;
      opacity: .7;
    }
  }
}

// Dark theme
.theme-gray,
.theme-dark {
  .item-card {
    .card__title {
      a {
        color: $theme_dark_text_color;
      }
    }
  }
  .card-white {
    color: $theme_dark_text_color;
    h1, h2, h3, h4, h5, h6 {
      color: $theme_dark_text_color;
    }
  }
}
.theme-gray {
  .card-white {
    background-color: $theme_gray_box_bg;
  }
}
.theme-dark {
  .card-white {
    background-color: $theme_dark_box_bg;
  }
}
