// based on bootstrap breadcrumb

.breadcrumb {
  text-transform: uppercase;
  color: #ccc;
  font-size: 11px;

  a {
    color: #ccc;
    &:hover {
      color: $link-color;
    }
  }
}
